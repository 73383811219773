import React, { useState, useEffect } from "react";

import "./pdf-template.scss";

import imgDdArt from "../../images/discussion-guide/personalize/ddg-art.svg";
import imgWakix from "../../images/discussion-guide/personalize/wakix.svg";
import imgCheckmark from "../../images/discussion-guide/personalize/checkmark.png";
import imgLogo from "../../images/discussion-guide/personalize/harmony-logo.png";
import imgWakixLogo from "../../images/discussion-guide/personalize/wakix.svg";

const getUrlParameter = (name) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
};

function PdfTemplate() {
  const [answers, setAnswers] = useState(null);
  useEffect(() => {
    const currentParams = getUrlParameter("params");
    if (currentParams) {
      setAnswers(JSON.parse(decodeURIComponent(getUrlParameter("params"))));
    }
  }, []);
  return (
    <div className="pdf-template">
      <table className="wrapper">
        <tr>
          <td style={{ verticalAlign: "top" }}>
            <table className="header">
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <img src={imgDdArt} alt="" />
                </td>
                <td style={{ verticalAlign: "top" }}>
                  <p className="page-header" style={{ paddingBottom: 15 }}>
                    My Conversation Plan
                  </p>
                  <p>
                    You answered a few questions about how you're managing
                    excessive
                    <br />
                    daytime sleepiness (EDS) or cataplexy in narcolepsy. Below
                    you'll find
                    <br />a summary of your responses.
                    {" "}
                    <span className="blue bold">
                      Use them as a guide during your next
                      <br /> conversation with your healthcare provider.
                    </span>
                  </p>
                </td>
                <td style={{ verticalAlign: "top" }} align="right">
                  <img src={imgWakix} alt="" />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td align="center">
            <table className="row row-1" align="center">
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <table className="col">
                    <tr>
                      <td className="header brain">My Life With Narcolepsy</td>
                    </tr>
                    <tr>
                      <td className="body" style={{ verticalAlign: "top" }}>
                        <ul>
                          <li>
                            <span>
                              In my day-to-day life, EDS or cataplexy in
                              narcolepsy
                            </span>
                            <ul>
                              {answers?.Q1_A1 && (
                                <li>
                                  <span>
                                    Makes it harder to maintain relationships
                                    <br /> or a social life
                                  </span>
                                </li>
                              )}

                              {answers?.Q1_A2 && (
                                <li>
                                  <span>
                                    Creates challenges when it comes to work
                                    <br /> or school
                                  </span>
                                </li>
                              )}

                              {answers?.Q1_A3 && (
                                <li>
                                  <span>
                                    Affects my daily activities or routine
                                  </span>
                                </li>
                              )}

                              {answers?.Q1_A4 && (
                                <li>
                                  <span>{answers?.Q1_A4_other}</span>
                                </li>
                              )}
                            </ul>
                          </li>
                          <li>
                            <span>
                              How much does EDS or cataplexy in
                              <br /> narcolepsy impact your day-to-day life?
                            </span>
                            <br />
                            <span className="response">{answers?.Q2_A}</span>
                          </li>
                          <li>
                            <span>
                              What is your top goal for managing your EDS or
                              <br /> cataplexy in narcolepsy?
                            </span>
                            <br />
                            <span className="response">{answers?.Q3_A}</span>
                          </li>
                          <li>
                            <span>
                              How often do you feel you reach this goal?
                            </span>
                            <br />
                            <span className="response">{answers?.Q4_A}</span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </td>
                <td
                  style={{ verticalAlign: "top" }}
                  align="right"
                  className="col-2"
                >
                  <table className="col">
                    <tr>
                      <td className="header treatment">
                        My Treatment Experience
                      </td>
                    </tr>
                    <tr>
                      <td className="body" style={{ verticalAlign: "top" }}>
                        <ul>
                          <li>
                            <span>
                              When it comes to my treatment plan, I am
                            </span>
                            <ul>
                              {answers?.Q5_A1 && (
                                <li>
                                  <span>Not achieving my treatment goals</span>
                                </li>
                              )}

                              {answers?.Q5_A2 && (
                                <li>
                                  <span>
                                    Having a hard time finding what works
                                    <br /> for me
                                  </span>
                                </li>
                              )}

                              {answers?.Q5_A3 && (
                                <li>
                                  <span>
                                    Concerned about stimulants or the
                                    <br /> potential for abuse
                                  </span>
                                </li>
                              )}

                              {answers?.Q5_A4 && (
                                <li>
                                  <span>
                                    Looking for a dosing schedule that works
                                    <br /> for me
                                  </span>
                                </li>
                              )}

                              {answers?.Q5_A5 && (
                                <li>
                                  <span>Wondering about side effects</span>
                                </li>
                              )}

                              {answers?.Q5_A6 && (
                                <li>
                                  <span>
                                    I have no concerns at the current time
                                  </span>
                                </li>
                              )}

                              {answers?.Q5_A7 && (
                                <li>
                                  <span>{answers?.Q5_A7_other}</span>
                                </li>
                              )}
                            </ul>
                          </li>
                          <li>
                            <span>
                              How satisfied are you with your current
                              <br /> treatment plan?
                            </span>
                            <br />
                            <span className="response">{answers?.Q6_A}</span>
                          </li>
                          <li>
                            <span>
                              How ready are you to consider a change to
                              <br /> your treatment?
                            </span>
                            <br />
                            <span className="response">{answers?.Q7_A}</span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td align="center">
            <table className="row row-2">
              <tr>
                <td style={{ verticalAlign: "top" }} align="right">
                  <table>
                    <tr>
                      <td className="header question" colspan="2">
                        My Questions About WAKIX<sup className="reg">&reg;</sup>
                        &nbsp;(pitolisant)
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          verticalAlign: "top",
                          paddingTop: 15,
                          paddingBottom: 15,
                        }}
                        className="body"
                      >
                        <ul>
                          {answers?.Q8_A1 ? (
                            <li className="active">
                              <img src={imgCheckmark} alt="" />
                              <span>How does WAKIX work?</span>
                            </li>
                          ) : (
                            <li>
                              <span>How does WAKIX work?</span>
                            </li>
                          )}

                          {answers?.Q8_A2 ? (
                            <li className="active">
                              <img src={imgCheckmark} alt="" />
                              <span>Is WAKIX a stimulant?</span>
                            </li>
                          ) : (
                            <li>
                              <span>Is WAKIX a stimulant?</span>
                            </li>
                          )}

                          {answers?.Q8_A3 ? (
                            <li className="active">
                              <img src={imgCheckmark} alt="" />
                              <span>Is WAKIX a controlled substance?</span>
                            </li>
                          ) : (
                            <li>
                              <span>Is WAKIX a controlled substance?</span>
                            </li>
                          )}
                        </ul>
                      </td>
                      <td
                        style={{
                          verticalAlign: "top",
                          paddingTop: 15,
                          paddingBottom: 15,
                        }}
                        className="body"
                      >
                        <ul>
                          {answers?.Q8_A4 ? (
                            <li className="active">
                              <img src={imgCheckmark} alt="" />
                              <span>How is WAKIX taken?</span>
                            </li>
                          ) : (
                            <li>
                              <span>How is WAKIX taken?</span>
                            </li>
                          )}

                          {answers?.Q8_A5 ? (
                            <li className="active">
                              <img src={imgCheckmark} alt="" />
                              <span>
                                How long might it take for WAKIX to work?
                              </span>
                            </li>
                          ) : (
                            <li>
                              <span>
                                How long might it take for WAKIX to work?
                              </span>
                            </li>
                          )}

                          {answers?.Q8_A6 ? (
                            <li className="active">
                              <img src={imgCheckmark} alt="" />
                              <span>
                                What are the possible side effects of WAKIX?
                              </span>
                            </li>
                          ) : (
                            <li>
                              <span>
                                What are the possible side effects of WAKIX?
                              </span>
                            </li>
                          )}

                          {answers?.Q8_A7 ? (
                            <li className="active">
                              <img src={imgCheckmark} alt="" />
                              <span>
                                Do you think WAKIX might be a treatment option
                                for me?
                              </span>
                            </li>
                          ) : (
                            <li>
                              <span>
                                Do you think WAKIX might be a treatment option
                                for me?
                              </span>
                            </li>
                          )}
                        </ul>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td align="center">
            <table className="indication">
              <tr>
                <td colspan="2" vAlign="bottom">
                  <p className="blue">Indications and Usage</p>
                  <p style={{ paddingBottom: 0, marginBottom: 0 }}>
                    WAKIX is a prescription medicine used to treat excessive
                    daytime sleepiness (EDS) or sudden onset of weak or
                    <br />
                    paralyzed muscles (cataplexy) in adults with narcolepsy.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top", paddingTop: 20 }}>
                  <p className="blue" style={{ paddingTop: 0, marginTop: 0 }}>
                    Important Safety Information
                  </p>
                  <p style={{ paddingTop: 0 }} >
                    <b>
                      Do not take WAKIX if you are allergic to pitolisant or any
                      <br />
                      ingredient in WAKIX, or if you have severe liver disease.
                    </b>
                  </p>
                </td>
                <td
                  className="semi-bold"
                  style={{ verticalAlign: "top", paddingTop: 20 }}
                >
                  Please see Important Safety Information
                  <br />
                  continued on following page and
                  <br />
                  accompanying{" "}
                  <a
                    href="https://wakixhcp.com/pdf/wakix-tablets-pi.pdf"
                    className="link trk_isi_fpi"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Full Prescribing Information
                  </a>
                  .
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr className="isi-template-container" style={{ marginTop: 50 }}>
          <td align="center">
            <table className="isi">
              <tr>
                <td className="blue" colspan="2" style={{ paddingBottom: 15 }}>
                  <b>Important Safety Information (continued)</b>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>
                      Tell your healthcare provider about all your medical
                      conditions, including if you have heart rhythm
                      irregularities, were born with a heart condition, or the
                      levels of electrolytes in your blood are too high or too
                      low.
                    </b>{" "}
                    WAKIX<sup className="reg">&reg;</sup> (pitolisant) has an
                    effect on the electrical activity of the heart known as QT
                    prolongation. Medicines with this effect can lead to
                    disturbances in heart rhythm, which are more likely in
                    patients with risk factors such as certain heart conditions,
                    or when taken in combination with other medicines that
                    affect QT. Tell your healthcare provider about all the other
                    medicines you take.
                  </p>
                  <p style={{ paddingTop: 15 }}>
                    The risk of QT prolongation may be greater in patients with
                    liver or kidney disease. WAKIX is not recommended in
                    patients with end-stage kidney disease.
                  </p>

                  <p style={{ paddingTop: 15 }}>
                    <b>The most common side effects seen with WAKIX</b> were
                    insomnia, nausea, and anxiety. Other side effects included
                    headache, upper respiratory tract infection, musculoskeletal
                    pain, heart rate increased, and decreased appetite. These
                    are not all the possible side effects of WAKIX. Tell your
                    healthcare provider about any side effect that bothers you
                    or that does not go away.
                  </p>

                  <p style={{ paddingTop: 15 }}>
                    <b>
                      Tell your healthcare provider about all the medicines you
                      take or plan to take, including prescription and
                      over-the-counter medicines.
                    </b>{" "}
                    Some medicines can increase the amount of WAKIX that gets
                    into your blood and some medicines can decrease the amount
                    of WAKIX that gets into your blood. The dosage of WAKIX may
                    need to be adjusted if you are taking these medicines.
                  </p>
                  <p style={{ paddingTop: 15 }}>
                    WAKIX can also decrease the effectiveness of some medicines,
                    including hormonal birth control methods. You should use an
                    alternative non-hormonal birth control method during
                    treatment with WAKIX and for at least 21 days after
                    discontinuation of treatment.
                  </p>

                  <p style={{ paddingTop: 15 }}>
                    <b>
                      Tell your healthcare provider if you are pregnant or
                      planning to become pregnant.
                    </b>{" "}
                    There is a pregnancy exposure registry that monitors
                    pregnancy outcomes in women who are exposed to WAKIX during
                    pregnancy. You are encouraged to enroll in the WAKIX
                    pregnancy registry if you become pregnant while taking
                    WAKIX. To enroll or obtain information from the registry,
                    call <span className="nobr">1-800-833-7460</span>.
                  </p>

                  <p style={{ paddingTop: 15 }}>
                    <b>
                      The safety and effectiveness of WAKIX have not been
                      established in patients less than 18 years of age.
                    </b>
                  </p>

                  <p style={{ paddingTop: 15 }}>
                    You are encouraged to report negative side effects of
                    prescription drugs to the FDA. Visit{" "}
                    <a
                      href="https://www.fda.gov/medwatch"
                      className="link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.fda.gov/medwatch
                    </a>
                    , or call 1-800-FDA-1088. You can also report negative side
                    effects to Harmony Biosciences at 1-800-833-7460.
                  </p>
                  <p style={{ marginTop: 20 }}>
                    <b>
                      Please see accompanying{" "}
                      <a
                        href="https://wakixhcp.com/pdf/wakix-tablets-pi.pdf"
                        className="link trk_isi_fpi blue"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Full Prescribing Information
                      </a>
                      .
                    </b>
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td align="center">
            <table className="footer">
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <img src={imgLogo} alt="Harmony Biosciences Logo" />
                </td>
                <td style={{ verticalAlign: "top", textAlign: "left" }}>
                  <p>
                    WAKIX is a registered trademark of Bioprojet Pharma, Ltd.
                  </p>
                  <p>
                    Harmony Biosciences name and logo are registered trademarks.
                  </p>
                  <p>
                    &copy; 2023 Harmony Biosciences.{" "}
                    <span className="nowrap">All rights reserved.</span>
                  </p>
                  <p>US-WAK-2300496/Dec 2023</p>
                </td>
                <td style={{ verticalAlign: "top" }}>
                  <img src={imgWakixLogo} alt="" />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default PdfTemplate;
